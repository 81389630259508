import styled, { css } from 'styled-components'

const styles = {
  standard: css`
    background-color: #FFF;
    color: #21233f;
    border: 1px solid rgba(203, 203, 203, .4);
  `,
  primary: css`
    background-color: #6050DC;
    border: 0;
    color: #FFF;
  `,
  borderless: css`
    background-color: transparent;
    color: #21233f;
    border: 0;
    &:hover {
      box-shadow: none;
      transform: none;
    }
  `
}

export const StyledBase = styled.button`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  padding: 15px 40px;
  border-radius: 50px;
  transition: all .15s ease;
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    transform: translateY(-1px);
  }
  svg {
    position: relative;
    top: 1px;
    margin: 0 10px
  }
  ${props => styles[props.btnStyle]}
`
