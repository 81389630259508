import {
  GET_REQUESTS,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_ERROR,
  ADD_SICK_REQUEST,
  ADD_SICK_REQUEST_SUCCESS,
  ADD_SICK_REQUEST_ERROR,
  DELETE_SICK_REQUEST,
  DELETE_SICK_REQUEST_SUCCESS,
  DELETE_SICK_REQUEST_ERROR
} from '../constants'

export const getUserRequests = id => {
  return async dispatch => {
    dispatch({
      type: GET_REQUESTS
    })
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/${id}/requests`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
          'Content-Type': 'application/json'
        }
      }
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: GET_REQUESTS_SUCCESS,
        payload: { id, body }
      })
    } else {
      dispatch({
        type: GET_REQUESTS_ERROR,
        payload: body
      })
    }
  }
}

export const addSickRequest = params => {
  return async dispatch => {
    dispatch({ type: ADD_SICK_REQUEST })
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/requests`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      }
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: ADD_SICK_REQUEST_SUCCESS,
        payload: { id: params.requester, body }
      })
    } else {
      dispatch({
        type: ADD_SICK_REQUEST_ERROR,
        payload: body
      })
    }
  }
}

export const deleteRequest = id => {
  return async dispatch => {
    dispatch({ type: DELETE_SICK_REQUEST })
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/requests/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
          'Content-Type': 'application/json'
        }
      }
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: DELETE_SICK_REQUEST_SUCCESS,
        payload: body
      })
    } else {
      dispatch({
        type: DELETE_SICK_REQUEST_ERROR,
        payload: body
      })
    }
  }
}
