import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import { getTeam } from '../../actions/team'
import { createCompany } from '../../actions/companies'

import CompanyForm from '../../components/CompanyForm'


class NewCompany extends Component {
  state = {}

  componentDidMount () {
    if (this.props.team.members.length === 0) {
      this.props.getTeam()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.newCompany.loading && !this.props.newCompany.error) {
      this.props.history.push('/dashboard/companies')
    }
  }

  render () {
    const { team: { members, loading } } = this.props

    if (loading) {
      return null
    }

    return (
      <CompanyForm members={members} method="create" {...this.props} />
    )
  }
}

const mapStateToProps = ({ team, companies }) => ({ team, newCompany: companies.newCompany })

const mapDisptachToProps = dispatch => bindActionCreators({ getTeam, createCompany }, dispatch)

export default withRouter(
  connect(
    mapStateToProps,
    mapDisptachToProps
  )(NewCompany)
)
