import styled, { css } from 'styled-components'

export const StyleBase = styled.div`
  margin-bottom: 30px;
  ${props => props.hasError && css`
    color: #e6903a;
    input {
      border-color: #e6903a;
    }
  `}
`
const TextFieldStyle = css`
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.5;
  background-color: #FFF;
  border: 1px solid #D9DADC;
  border-radius: 4px;
  &:focus {
    border-color: #919496;
    outline: 0;
  }
`
export const InputStyle = styled.input`
  ${TextFieldStyle};
  height: 40px;
`

export const TextAreaStyle = styled.textarea`
  ${TextFieldStyle};
`

export const Label = styled.label`
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
`

export const ErrorMsg = styled.span`
  font-size: 14px;
  color: #e6903a;
  padding-left: 10px;
`
