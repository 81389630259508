import styled from 'styled-components'

export const AppLayout = styled.div`
`

export const MainContent = styled.div`
  padding: 0 40px;
  margin: 0 auto;
  max-width: 1020px;
`
