import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ManageTeam from '../../views/team'
import Companies from '../../views/companies'
import NewCompany from '../../views/newCompany'
import ViewCompany from '../../views/viewCompany'
import Settings from '../../views/settings'
import NavBar from './navbar'

import { AppLayout, MainContent } from './style'

const Dashboard = ({ authentification }) => {
  const { currentAdmin: user } = authentification

  return (
    <AppLayout>
      <NavBar />
      <MainContent>
        <Switch>
          <Route
            exact
            path="/dashboard"
            render={_ => <ManageTeam user={user} />}
          />
          <Route
            path="/dashboard/team"
            render={_ => <ManageTeam user={user} />}
          />
          <Route
            exact
            path="/dashboard/companies"
            render={_ => <Companies user={user} />}
          />
          <Route
            exact
            path="/dashboard/companies/new"
            render={_ => <NewCompany user={user} />}
          />
          <Route
            path="/dashboard/companies/:id"
            render={props => <ViewCompany {...props} user={user} />}
          />
          <Route
            path="/dashboard/settings"
            render={_ => <Settings user={user} />}
          />

        </Switch>
      </MainContent>
    </AppLayout>
  )
}

export default Dashboard
