import React from 'react'

import { StyleBase, ProfilePic, MembersList } from './style'

const CompanyCard = ({ name, team, description, logo_url }) => {
  return (
    <StyleBase>
      <img src={logo_url} alt={name}/>
      <div>
        <h3>{name}</h3>
        <p>{description}</p>
        <MembersList>
          {
            team.map((x, i) => <ProfilePic key={i}><img src={x.member.avatar} alt=""/></ProfilePic>)
          }
        </MembersList>
      </div>
    </StyleBase>
  )
}

export default CompanyCard
