import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import qs from 'qs'

import { signIn } from '../../actions/authentification'
import { StyleBase } from './style'

class Authentification extends Component {
  state = {
    error: false,
    loading: true
  }
  // async componentWillMount() {
  //   const query = this.props.location.search.split('?')[1]
  //   const parseQuery = qs.parse(query)
  //   if (parseQuery.error || !parseQuery.code) {
  //     this.props.history.push('/')
  //   } else {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_URL}/api/slack/oauth?code=${parseQuery.code}`)
  //       const body = await response.json()
  //       if (!body.ok) {
  //         this.setState({
  //           error: true,
  //           loading: false
  //         })
  //       }
  //       localStorage.setItem('Authorization', body.token)
  //       this.setState({ loading: false })
  //     } catch (error) {
  //       console.log({error})
  //       this.setState({
  //         error: true,
  //         loading: false
  //       })
  //     }
  //   }
  // }
  componentDidMount () {
    const query = this.props.location.search.split('?')[1]
    const parseQuery = qs.parse(query)
    if (parseQuery.error || !parseQuery.code) {
      this.props.history.push('/')
    } else {
      this.props.signIn(parseQuery.code)
    }
  }
  render() {
    const { error, loading } = this.props.authentification
    if (loading) {
      return (
        <StyleBase>
          <h3>Loading...</h3>
        </StyleBase>
      )
    }

    return (
      <StyleBase>
        {error ? (
          <React.Fragment>
            <a href="/">
              <img src={require('../../assets/Gemo.svg')} style={{ width: '270px' }} alt='Gemography' />
            </a>
            <h2>Oh nu, something went wrong</h2>
            <p>
              Apologies, but it looks like something isn't right. try again later!
            </p>
          </React.Fragment>
        ) : (
          <Redirect to="/dashboard" />
        )}
      </StyleBase>
    )
  }
}

const mapStateToProps = ({ authentification }) => {
  return { authentification }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ signIn }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Authentification)
