import React, { Component } from 'react'
import mapValues from 'lodash/mapValues'
import PropTypes from 'prop-types'

import Input from '../../components/Input'
import Button from '../../components/Button'
import TeamAutoComplete from '../../components/TeamAutoComplete'

import { StyleBase, Row, Overview, Form, ButtonContainer, ErrorMessage } from './style'

class CompanyForm extends Component {
  constructor(props) {
    super(props)
    const {
      companyDetails: { name = '', description = '', logo_url = '', cto, team = [] } = {},
    } = props
    this.state = {
      form: {
        companyName: name,
        description: description,
        companyLogo: logo_url,
        ctoName: cto ? cto.name : '',
        ctoEmail: cto ? cto.email : '',
        teamMembers: team.map(x => x.member)
      },
      error: {}
    }
  }

  onInputChange = e => {
    const { value, name } = e.target
    const { form, error } = this.state
    this.setState({
      form: {
        ...form,
        [name]: value
      },
      error: {
        ...error,
        [name]: null
      }
    })
  }

  buildRequestBody = _ => {
    const { form } = this.state
    return {
      name: form.companyName,
      logo_url: form.companyLogo,
      description: form.description,
      cto: {
        name: form.ctoName,
        email: form.ctoEmail
      },
      team: form.teamMembers.map(x => x._id)
    }
  }

  onSubmitForm = _ => {
    const error = mapValues(this.validation(), v => !v)
    const isValid = Object.keys(error).every(k => !error[k])
    if (!isValid) {
      this.setState({ error })
    } else {
      const { method } = this.props
      return method === 'create'
        ? this.props.createCompany(this.buildRequestBody())
        : this.props.updateCompany(this.buildRequestBody())
    }
  }

  validation = _ => {
    const { form } = this.state
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return {
      companyName: !!form.companyName.trim(),
      description: !!form.description.trim(),
      companyLogo: !!form.companyLogo.trim(),
      ctoName: !!form.ctoName.trim(),
      ctoEmail: emailRegex.test(form.ctoEmail)
    }
  }

  isValid = _ => {
    const validation = this.validation()
    return Object.keys(validation)
  }

  onTeamMembersChange = teamMembers => {
    const { form } = this.state
    this.setState({
      form: {
        ...form,
        teamMembers
      }
    })
  }

  render() {
    const {
      form: { companyName, description, companyLogo, ctoName, ctoEmail, teamMembers },
      error
    } = this.state
    const { members, loading, method, newCompany, editCompany } = this.props

    return (
      <StyleBase>
        <h1>{method === 'create' ? 'New' : 'Update'} company</h1>
        <Row>
          <Overview>
            <h4>Basics</h4>
            <p>
              Anything related to this company, this helps others get a quick
              overview about the company.
            </p>
          </Overview>
          <Form>
            <Input
              error={error.companyName && 'Please enter the company name.'}
              value={companyName}
              onChange={this.onInputChange}
              id="name"
              name="companyName"
              label="Company name"
              placeholder=""
            />
            <Input
              error={
                error.description &&
                'Please enter few words that describe the company mission.'
              }
              textarea
              rows="3"
              value={description}
              onChange={this.onInputChange}
              id="description"
              name="description"
              label="Short Description"
              placeholder=""
            />
            <Input
              error={error.companyLogo && 'Company logo url.'}
              value={companyLogo}
              onChange={this.onInputChange}
              id="companyLogo"
              name="companyLogo"
              label="Company logo"
              placeholder="https://"
            />
          </Form>
        </Row>
        <Row>
          <Overview>
            <h4>CTO</h4>
            <p>
              Basic information about the CTO so they can be updated with their
              team members.
            </p>
          </Overview>
          <Form>
            <Input
              error={error.ctoName && 'Please enter the CTO name.'}
              value={ctoName}
              onChange={this.onInputChange}
              id="ctoName"
              name="ctoName"
              label="Fullname"
              placeholder=""
            />
            <Input
              error={error.ctoEmail && 'Please enter the CTO email.'}
              value={ctoEmail}
              onChange={this.onInputChange}
              id="ctoEmail"
              name="ctoEmail"
              label="Email"
              placeholder=""
            />
          </Form>
        </Row>
        <Row>
          <Overview>
            <h4>Team members</h4>
            <p>Hackers that are collaborating remotely with this company.</p>
          </Overview>
          <Form>
            <TeamAutoComplete
              value={teamMembers}
              onChange={this.onTeamMembersChange}
              members={members.filter(member => member.is_employee)}
            />
            {
              newCompany && (
                <ErrorMessage>
                  <p>{newCompany.error}</p>
                </ErrorMessage>
              )
            }
            {
              editCompany && (
                <ErrorMessage>
                  <p>{editCompany.error}</p>
                </ErrorMessage>
              )
            }
          </Form>
        </Row>

        <ButtonContainer>
          <Button
            disabled={loading}
            onClick={this.onSubmitForm}
            btnStyle="primary">
            {method === 'create' ? 'Add ' : 'Update'} company
          </Button>
        </ButtonContainer>
      </StyleBase>
    )
  }
}

CompanyForm.defaultProps = {
  members: []
}

CompanyForm.propTypes = {
  method: PropTypes.oneOf(['create', 'update']).isRequired
}

export default CompanyForm
