import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getCurrentUser } from '../actions/authentification'

const EnsureAuth = ({ component: Component, getCurrentUser, ...rest }) => {
  const { currentAdmin, loading } = rest.authentification
  const token = localStorage.getItem('Authorization')

  if (!currentAdmin && token) {
    getCurrentUser(token)
    if (loading) {
      return <div />
    }
  }

  return (
    <Route
      path={rest.path}
      render={props =>
        currentAdmin ? <Component {...props} {...rest} /> : <Redirect to="/" />
      }
    />
  )
}

const mapStateToProps = ({ authentification }) => {
  return { authentification }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getCurrentUser }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnsureAuth)
