import styled from 'styled-components'

export const StyleBase = styled.div`
  position: relative;
`

export const InputStyle = styled.input`
  width: 130px;
  border: 0;
  font-size: 12px;
  outline: 0;
  height: 25px;
  margin-top: 2px;
  margin-bottom: 6px;
  font-size: 14px;
`

export const InputContainer = styled.div`
  width: 100%;
  padding: 6px 12px 0;
  background-color: #FFF;
  border: 1px solid #D9DADC;
  border-radius: 4px;
  min-height: 40px;
  display: flex;
  flex-wrap: wrap;
  &:focus {
    border-color: #919496;
    outline: 0;
  }
`

export const MembersList = styled.div`
  max-height: 360px;
  overflow-y: scroll;
  background-color: #FFF;
  border: 1px solid #D9DADC;
  position: absolute;
  bottom: 100%;
  width: 100%;
`

export const MemberRow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  &:hover {
    background-color: #e2e2e2;
  }
  img {
    border-radius: 50%;
  }
  span {
    font-size: 14px;
    margin-left: 15px;
  }
`

export const Collaborator = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 6px;
  /* background-color: #e2e2e2; */
  &.focused {
    border-radius: 14px;
    background-color: #e2e2e2;
  }
  .close-btn {
    padding: 0 5px;
    margin-right: 15px;
    border-radius: 2px;
    &:hover {
      background-color: #b5b5b5;
      cursor: pointer;
    }
  }
  img {
    border-radius: 50%;
  }
  span {
    margin-left: 5px;
    font-size: 14px;
  }
`
