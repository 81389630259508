import React from 'react'

import { StyleBase } from './style'

const Home = () => {
  return (
    <StyleBase>
      <div>
        <img src={require('../../assets/Gemo.svg')} style={{ width: '270px' }} alt='Gemography' />
      </div>
      <h1>Welcome to Pop</h1>
      <p>
        Internal <a href='https://gemography.com'>Gemography</a> product for managing the team leave days and more.<br />
        <span>Ps: this an admin panel if you are not one <a href='https://netflix.com'>click here</a> </span>
      </p>
      <a className='slack-button' href={`https://slack.com/oauth/authorize?scope=identity.basic,identity.avatar,identity.email&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}/slack/auth`}>
        <span>Signin with Slack</span>
      </a>
    </StyleBase>
  )
}

export default Home
