import {
  GET_HOLIDAYS,
  GET_HOLIDAYS_FAILED,
  ADD_HOLIDAY,
  ADD_HOLIDAY_SUCCESS,
  ADD_HOLIDAY_ERROR,
  DELETE_HOLIDAY,
  DELETE_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_ERROR
} from '../constants'

const initialState = {
  loading: true,
  error: null,
  list: [],
  deleteOrUpdateOpts: {
    loading: false,
    error: null
  }
}

export default function holidays(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_HOLIDAYS:
      return {
        ...state,
        list: payload.holidays,
        loading: false
      }

    case GET_HOLIDAYS_FAILED:
      return {
        ...state,
        loading: false,
        error: payload.error
      }

    case ADD_HOLIDAY:
    case DELETE_HOLIDAY:
      return {
        ...state,
        deleteOrUpdateOpts: {
          loading: true,
          error: null
        }
      }

    case ADD_HOLIDAY_SUCCESS:
      return {
        ...state,
        list: [...state.list, payload.holiday],
        deleteOrUpdateOpts: {
          loading: false,
          error: null
        }
      }

    case DELETE_HOLIDAY_SUCCESS:
      return {
        ...state,
        list: state.list.filter(x => x._id !== payload.holiday),
        deleteOrUpdateOpts: {
          loading: false,
          error: null
        }
      }

    case ADD_HOLIDAY_ERROR:
    case DELETE_HOLIDAY_ERROR:
      return {
        ...state,
        deleteOrUpdateOpts: {
          loading: true,
          error: payload.error
        }
      }

    default:
      return state
  }
}
