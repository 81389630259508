import styled from 'styled-components'

export const StyleBase = styled.div`
  padding: 30px 0;
`

export const Header = styled.header`
  h1 {
    margin: 0;
  }
  p {
    color: hsl(0,0%, 60%);
  }
`
