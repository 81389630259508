import React, { Component } from 'react'
import DatePicker from 'react-day-picker/DayPickerInput'
import xor from 'lodash/xor'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import moment from 'moment'

import { updateTeamMember } from '../../../actions/team'
import {
  getUserRequests,
  addSickRequest,
  deleteRequest
} from '../../../actions/requests'
import SickDays from './sickDays'
import './style.css'

import { Input } from 'ur-majorelle'
import {
  StyleBase,
  Header,
  Avatar,
  FormGroup,
  LeaveBalance,
  Approvers,
  ApproverProfile,
  GroupBtn,
  Button,
  UserBio,
  AboutSection,
  LeaveManagement,
  InlineForm
} from './style'

class UserDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      approvers: props.currentApprovers,
      suggApprovers: [],
      isAddBtn: true,
      start_date: props.member.start_date
        ? new Date(props.member.start_date).toLocaleDateString()
        : '',
      leave_balance: props.member.leave_balance,
      job_title: props.member.job_title,
      bio: props.member.bio,
      role: props.role,
      birthday: props.member.birthday ? moment(props.member.birthday).format('l') : '',
      phoneNumber: props.member.phone_number ? props.member.phone_number : '',
      // ERROR HANDING 
      phoneNumberErr: false,
      birthdayErr: false,
      jobTitleErr: false,
      startDateErr: false
    }
  }
  componentDidMount() {
    // avoid doing an HTTP request if the user already has the requests key
    if (!this.props.member.requests) {
      this.props.getUserRequests(this.props.member._id)
    }
  }
  getApprovers = () => {
    this.setState({
      suggApprovers: this.props.possibleApprovers,
      isAddBtn: false
    })
  }
  cancelAddApprovers = () => {
    this.setState({ isAddBtn: true, suggApprovers: [] })
  }
  addApprover = id => {
    this.setState({
      suggApprovers: this.state.suggApprovers.map(x =>
        x._id === id ? { ...x, selected: !x.selected } : x
      )
    })
  }
  removeApprover = id => {
    this.setState({
      approvers: this.state.approvers.map(x =>
        x._id === id ? { ...x, selected: !x.selected } : x
      )
    })
  }
  onInputChange = e => {
    const { value } = e.target
    const leave_balance = parseInt(value)
    this.setState({
      leave_balance: isNaN(leave_balance) ? value : leave_balance
    })
  }
  onBioChange = e => {
    const { value: bio } = e.target
    this.setState({ bio })
  }
  onJobChange = e => {
    const { value: job_title } = e.target
    this.setState({ job_title })
  }
  onPhoneChange = e => {
    const { value: phoneNumber } = e.target
    this.setState({ phoneNumber })
  }
  onLeaveDaysBtnClick = op => {
    if (this.state.leave_balance === 0 && op === 'minus') return

    const leave_balance =
      op === 'minus'
        ? this.state.leave_balance - 0.5
        : this.state.leave_balance + 0.5

    this.setState({ leave_balance })
  }
  onStartDateChange = start_date => {
    this.setState({ start_date: new Date(start_date).toLocaleDateString() })
  }
  onBirthdayChange = e => {
    const { value: birthday } = e.target
    this.setState({ birthday })
  }
  onSaveChanges = async () => {
    const {
      approvers,
      suggApprovers,
      start_date,
      leave_balance,
      bio,
      job_title,
      phoneNumber,
      birthday
    } = this.state

    this.setState({
      phoneNumberErr: false,
      birthdayErr: false,
      jobTitleErr:false,
      startDateErr:false
    })
    if(!phoneNumber) {
       this.setState({phoneNumberErr: true})
    }

    if(!job_title) {
      this.setState({jobTitleErr: true})
   }
   if(!start_date) {
    this.setState({startDateErr: true})
   }
    if(!phoneNumber || !job_title || !start_date) {
      document.getElementsByClassName("sc-iwsKbI")[0].scrollTo({ top: 0, behavior: 'smooth' });
      return
    } 
    console.log(
      {
        approvers,
        suggApprovers,
        start_date,
        leave_balance,
        bio,
        job_title,
        phoneNumber,
        birthday
      } 
    )
    const { member } = this.props
    const isSelecetd = x => x.selected
    const isApproverAdded = !!suggApprovers.filter(isSelecetd).length
    const isApproverRemoved = !!approvers.filter(isSelecetd).length
    const isFormUpdated =
      leave_balance !== member.leave_balance || start_date !== member.start_date

    const requests = [
      {
        updated: isFormUpdated,
        url: `${process.env.REACT_APP_API_URL}/api/users/${member._id}`,
        params: {
          method: 'PUT',
          body: JSON.stringify({ start_date, leave_balance, bio, job_title, birthday, phone_number:phoneNumber })
        }
      },
      {
        updated: isApproverAdded,
        url: `${process.env.REACT_APP_API_URL}/api/users/${
          member._id
        }/approvers`,
        params: {
          method: 'PUT',
          body: JSON.stringify({
            approvers: suggApprovers.filter(isSelecetd).map(x => x._id)
          })
        }
      },
      {
        updated: isApproverRemoved,
        url: `${process.env.REACT_APP_API_URL}/api/users/${
          member._id
        }/approvers`,
        params: {
          method: 'DELETE',
          body: JSON.stringify({
            approvers: approvers.filter(isSelecetd).map(x => x._id)
          })
        }
      }
    ]

    const filtredRequests = requests.filter(x => x.updated)
    if (!filtredRequests.length) {
      this.props.closeModal()
      return
    }

    try {
      await Promise.all(
        filtredRequests.map(x =>
          window
            .fetch(x.url, {
              ...x.params,
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('Authorization')
              }
            })
            .then(res => res.json())
        )
      )

      const newMember = {
        ...member,
        start_date,
        leave_balance,
        bio,
        job_title,
        birthday,
        phone_number:phoneNumber,
        approvers: [
          ...xor(
            member.approvers,
            approvers.filter(x => !!x.selected).map(x => x._id)
          ),
          ...suggApprovers.filter(x => x.selected).map(x => x._id)
        ]
      }
      this.props.updateTeamMember(newMember)
      this.props.closeModal()
    } catch (error) {
      console.log(error)
    }
  }
  render() {
    const { member, closeModal } = this.props

    const { isAddBtn, 
      leave_balance, 
      start_date, 
      bio, job_title, 
      birthday, 
      phoneNumber,
      phoneNumberErr,
      jobTitleErr,
      startDateErr 
      } = this.state
    return (
      <StyleBase id="randomo">
        <Header>
          <Avatar>
            <img src={member.avatar} alt={member.name} />
            {member.role !== 'user' && (
              <div className="avatar__tags">
                {member.role !== 'user' && (
                  <span className="tags__role">{member.role}</span>
                )}
              </div>
            )}
          </Avatar>
          <div className="user-details">
            <h2>{member.name}</h2>
            <div>
              <span>{member.email}</span>
            </div>
          </div>
        </Header>
        <div>
          <AboutSection>
            <h3>About</h3>
            <InlineForm>
              <FormGroup className="date-form">
                <label htmlFor="">Start date*</label>
                <p className="date-helper">When did the user start working at Gemography</p>
                <div className={startDateErr ? "datepicker-styles error" : 'datepicker-styles'}>
                  <DatePicker
                    placeholder="M/D/YYYY"
                    onDayChange={this.onStartDateChange}
                    value={start_date}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Input
                  label={'Job Title*'}
                  placeholder="Software Engineer"
                  helper={'Whenever you\'re lost, write Software Engineer'}
                  error={jobTitleErr  ? 'Job title is required' : ''}
                  value={job_title}
                  className={jobTitleErr ? 'error' : ''}
                  onChange={this.onJobChange}
                />
              </FormGroup>
            </InlineForm>
            <InlineForm>
              <FormGroup>
                <Input
                  label={'Phone Number*'}
                  placeholder="+212655321487"
                  helper={'Hiree\'s work phone number'}
                  error={phoneNumberErr  ? 'Phone number is required' : ''}
                  value={phoneNumber}
                  className={phoneNumberErr ? 'error' : ''}
                  onChange={this.onPhoneChange}
                />

              </FormGroup>
              <FormGroup>
              <Input
                  label={'Birthday'}
                  placeholder="MM/DD/YYYY"
                  helper={'Example: 16/01/1992'}
                  value={birthday}
                  onChange={this.onBirthdayChange}
                />
              </FormGroup>
            </InlineForm>
            <FormGroup>
              <UserBio>
                <label>Bio</label>
                <p>Describe in few words what fits this employee</p>
                <textarea
                  value={bio}
                  onChange={this.onBioChange}
                  name="bio"
                  rows="5"
                />
              </UserBio>
            </FormGroup>
          </AboutSection>

          <LeaveManagement>
            <h3>Leave management</h3>
            <InlineForm>
              <FormGroup>
                <label htmlFor="">Leave balance</label>
                <p>The amount of leave days available </p>
                <LeaveBalance>
                  <div
                    onClick={this.onLeaveDaysBtnClick.bind(this, 'minus')}
                    className="decrease-balance"
                  >
                    -
                  </div>
                  <input
                    type="text"
                    value={leave_balance}
                    onChange={this.onInputChange}
                  />
                  <div
                    onClick={this.onLeaveDaysBtnClick.bind(this, 'plus')}
                    className="increase-balance"
                  >
                    +
                  </div>
                </LeaveBalance>
              </FormGroup>
              <FormGroup>
                <label>Approvers</label>
                <Approvers>
                  <div className="approvers-list">
                    {this.state.approvers.map((x, i) => (
                      <ApproverProfile
                        onClick={() => this.removeApprover(x._id)}
                        removed={x.selected}
                        key={`a-${i}`}
                      >
                        <img src={x.avatar} alt={x.name} />
                        <span className="tooltip">{x.name}</span>
                      </ApproverProfile>
                    ))}
                  </div>
                  <div
                    className={`add-approver ${isAddBtn ? 'add' : 'close'}`}
                    onClick={() =>
                      isAddBtn ? this.getApprovers() : this.cancelAddApprovers()
                    }
                  />
                  <div className="sugg-approvers-list">
                    {this.state.suggApprovers.map((x, i) => (
                      <ApproverProfile
                        onClick={() => this.addApprover(x._id)}
                        added={x.selected}
                        delay={20 * (4 * i + 1)}
                        key={`a-${i}`}
                      >
                        <img src={x.avatar} alt={x.name} />
                        <span className="tooltip">{x.name}</span>
                      </ApproverProfile>
                    ))}
                  </div>
                </Approvers>
              </FormGroup>
            </InlineForm>
          </LeaveManagement>
          <SickDays
            requester={member._id}
            addSickRequest={this.props.addSickRequest}
            deleteRequest={this.props.deleteRequest}
            requests={member.requests}
          />
        </div>

        <GroupBtn>
          <Button borderless onClick={closeModal}>
            Cancel
          </Button>
          <Button primary onClick={this.onSaveChanges}>
            Save
          </Button>
        </GroupBtn>
      </StyleBase>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { updateTeamMember, getUserRequests, addSickRequest, deleteRequest },
    dispatch
  )
}

export default connect(
  null,
  mapDispatchToProps
)(UserDetails)
