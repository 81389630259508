import {
  GET_TEAM,
  GET_TEAM_ERROR,
  UPDATE_MEMBER,
  REFRESH_TEAM,
  REFRESH_TEAM_SUCCESS,
  REFRESH_TEAM_ERROR,
  GET_REQUESTS,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_ERROR,
  ADD_SICK_REQUEST,
  ADD_SICK_REQUEST_SUCCESS,
  ADD_SICK_REQUEST_ERROR,
  DELETE_SICK_REQUEST,
  DELETE_SICK_REQUEST_SUCCESS,
  DELETE_SICK_REQUEST_ERROR,
} from '../constants'

const intitialState = {
  members: [],
  error: null,
  loading: true,
  refreshTeamOps: {
    loading: false,
    error: null
  },
  getUserRequestsOps: {
    loading: false,
    error: null
  },
  deteleOrUpdateRequestOps: {
    loading: false,
    error: null
  }
}

export default function team(state = intitialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_TEAM:
      return {
        ...state,
        loading: false,
        members: [...state.members, ...payload.data]
      }

    case GET_TEAM_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.body
      }

    case UPDATE_MEMBER:
      const member = payload

      return {
        ...state,
        members: state.members.map(x => (x._id === member._id ? member : x))
      }

    case REFRESH_TEAM:
      return {
        ...state,
        refreshTeamOps: {
          loading: true,
          error: null
        }
      }

    case REFRESH_TEAM_SUCCESS:
      return {
        ...state,
        members: payload.data,
        refreshTeamOps: {
          loading: false,
          error: null
        }
      }
    case REFRESH_TEAM_ERROR:
      return {
        ...state,
        refreshTeamOps: {
          loading: false,
          error: payload.body
        }
      }

    case GET_REQUESTS:
      return {
        ...state,
        getUserRequestsOps: {
          loading: true,
          error: null
        }
      }

    case GET_REQUESTS_SUCCESS:
      return {
        ...state,
        members: state.members.map(member =>
          member._id !== payload.id
            ? member
            : { ...member, requests: payload.body.requests }
        ),
        getUserRequestsOps: {
          loading: false,
          error: null
        }
      }

    case GET_REQUESTS_ERROR:
      return {
        ...state,
        getUserRequestsOps: {
          loading: false,
          error: payload
        }
      }

    case ADD_SICK_REQUEST:
    case DELETE_SICK_REQUEST:
      return {
        ...state,
        deteleOrUpdateRequestOps: {
          loading: true,
          error: null
        }
      }
  
    case ADD_SICK_REQUEST_SUCCESS:
      return {
        ...state,
        members: state.members.map(member =>
          member._id !== payload.id
            ? member
            : { ...member, requests: [...member.requests, payload.body.request] }
        ),
        deteleOrUpdateRequestOps: {
          loading: false,
          error: null
        }
      }

    case DELETE_SICK_REQUEST_SUCCESS:
      return {
        ...state,
        members: state.members.map(member =>
          member._id !== payload.request.requester
            ? member
            : { ...member, requests: member.requests.filter(x => x._id !== payload.request._id) }
        ),
        deleteOrUpdateOpts: {
          loading: false,
          error: null
        }
      }

    case ADD_SICK_REQUEST_ERROR:
    case DELETE_SICK_REQUEST_ERROR:
      return {
        ...state,
        deteleOrUpdateRequestOps: {
          loading: false,
          error: payload.error
        }
      }

    default:
      return state
  }
}
