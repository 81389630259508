import { combineReducers } from 'redux'

import { USER_SIGN_OUT } from '../constants'
import authentification from './authentification'
import team from './team'
import companies from './companies'
import holidays from './holidays'

const reducers = combineReducers({
  authentification,
  team,
  companies,
  holidays
})

const rootReducer = (state, action) => {
  if (action.type === USER_SIGN_OUT) {
    state = undefined
  } 
  return reducers(state, action)
}

export default rootReducer
