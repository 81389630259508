import React, { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import format from 'date-fns/format'
import Button from '../../components/Button'
import Input from '../../components/Input'

import { getHolidays, addHoliday, deleteHoliday } from '../../actions/holidays'

import {
  StyleBase,
  HolidaySettings,
  HolidayContainer,
  Table,
  TableHeader,
  HolidayRow,
  DeleteBtn
} from './style'

class Settings extends Component {
  state = {
    name: '',
    day: null,
    end_date: null,
    addFormShown: false,
    removeOptShown: false
  }
  handleDayChange = day => {
    this.setState({ day })
  }
  handleEndDateChange = end_date => {
    this.setState({ end_date })
  }
  toggleAddForm = _ => {
    this.setState({ addFormShown: !this.state.addFormShown })
  }
  toggleEditOpt = _ => {
    this.setState({ removeOptShown: !this.state.removeOptShown })
  }
  handleInputChange = ({ target }) => {
    const { name, value } = target
    this.setState({ [name]: value })
  }
  componentDidMount() {
    this.props.getHolidays()
  }
  handleSubmitHoliday = e => {
    e.preventDefault()
    this.props.addHoliday({
      date: this.state.day,
      name: this.state.name,
      end_date: this.state.end_date
    })
    this.setState({ day: null, name: '', addFormShown: false, end_date: null })
  }
  render() {
    const { holidays } = this.props
    const { addFormShown, removeOptShown } = this.state
    if (holidays.loading) {
      return null
    }
    return (
      <StyleBase>
        <HolidaySettings>
          <h2>Holidays</h2>
          <Table>
            <TableHeader>
              <span>Name</span>
              <span>Start date</span>
              <span>End date</span>
              <div>
                {!addFormShown && !removeOptShown && (
                  <Button btnStyle="primary" onClick={this.toggleAddForm}>
                    Add
                  </Button>
                )}
                <Button btnStyle="borderless" onClick={this.toggleEditOpt}>
                  {removeOptShown ? 'Cancel' : 'Edit'}
                </Button>
              </div>
            </TableHeader>
            {addFormShown && (
              <HolidayContainer onSubmit={this.handleSubmitHoliday}>
                <Input
                  placeholder="Eid Sghir"
                  onChange={this.handleInputChange}
                  value={this.state.name}
                  name="name"
                />
                <DayPickerInput onDayChange={this.handleDayChange} />
                <DayPickerInput onDayChange={this.handleEndDateChange} />
                <div>
                  <Button
                    type="submit"
                    onClick={this.toggleAddForm}
                    btnStyle="borderless"
                  >
                    Cancel
                  </Button>
                  <Button btnStyle="borderless">Add</Button>
                </div>
              </HolidayContainer>
            )}
            {holidays.list.sort((a, b) => new Date(b.date) - new Date(a.date)).map(x => (
              <HolidayRow key={x._id}>
                <span>{x.name}</span>
                <span>{format(x.date, 'MMM. DD, YYYY')}</span>
                <span title="If there is no end date, this holiday will be treated like if it was a 1 day holiday">{x.end_date ? format(x.end_date, 'MMM. DD, YYYY') : '⚠️ End date missing'}</span>
                {true && <span></span>}
                {removeOptShown && <DeleteBtn onClick={() => this.props.deleteHoliday(x._id)}>Delete</DeleteBtn>}
              </HolidayRow>
            ))}
          </Table>
        </HolidaySettings>
      </StyleBase>
    )
  }
}

const mapStateToProps = ({ holidays }) => ({ holidays })

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getHolidays, addHoliday, deleteHoliday }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings)
