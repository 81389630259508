import { USER_SIGN_IN_SUCCESS, USER_SIGN_IN_ERROR } from '../constants'

const initialState = {
  loading: true,
  currentAdmin: null,
  token: null,
  error: null
}

export default function authentification (state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case USER_SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        currentAdmin: payload.user,
        token: payload.token,
        error: null
      }

    case USER_SIGN_IN_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error
      }

    default:
      return state
  }
}
