import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import rootReducer from './reducers' 
import Router from './routes'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Rubik:400,500,700');
  *,:after,:before{box-sizing:border-box;}
  body, html {
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
  }
  body {
    font-family: "Rubik", sans-serif;
    background-color: #f9f9f9;
    color: #21233f;
    line-height: 1.4;
  }
  h1 {
    font-size: 2.6em;
  }
  h1, h2, h3 {
    font-weight: 700;
    line-height: 1.2; 
  }
`

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
))

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <Router />
  </Provider>,
  document.getElementById('root')
)
