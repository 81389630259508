import React, { useState } from 'react'
import DatePicker from 'react-day-picker/DayPickerInput'
import format from 'date-fns/format'

import {
  SickDaysStyle,
  Table,
  RequestRow,
  RequestType,
  FormGroup,
  Button
} from './style'

const SickDays = ({ requests, addSickRequest, deleteRequest, requester }) => {
  const [startDate, setStartDate] = useState(new Date())
  const [duration, setDuration] = useState(0)
  const onSubmitSickDay = e => {
    e.preventDefault()
    if (!!duration && !!startDate) {
      addSickRequest({ duration, start_date: startDate, requester })
    }
  }
  return (
    <SickDaysStyle>
      <label>Sick days</label>
      <form onSubmit={onSubmitSickDay}>
        <FormGroup>
          <DatePicker
            placeholder='M-D-YYYY'
            onDayChange={day => setStartDate(day)}
            value={startDate}
          />
          <input
            value={duration}
            onChange={e => setDuration(e.target.value)}
            style={{ width: 40 }}
            placeholder='3'
          />
          <Button type='submit' borderless>
            + Add
          </Button>
        </FormGroup>
      </form>
      <p className="leave-history-p">Leave history</p>
      <div className="leave-history-table">
        <Table>
          <thead>
            <tr>
              <td>START AT</td>
              <td>REASON</td>
              <td>DURATION</td>
              <td>TYPE</td>
              <td />
            </tr>
          </thead>
          <tbody>
            {requests &&
            requests
              .sort((a, b) => new Date(b.start_date) - new Date(a.start_date))
              .map(request => (
                <RequestRow key={request._id}>
                  <td>{format(request.start_date, 'MMM. DD, YYYY')}</td>
                  <td
                    style={{
                      fontStyle: request.reason ? 'normal' : 'italic'
                    }}
                  >
                    {request.reason || 'unspecified'}
                  </td>
                  <td>{request.duration}</td>
                  <td>
                    <RequestType type={request.type}>{request.type}</RequestType>
                  </td>
                  {request.type === 'sick' ? (
                    <td>
                      <svg width='22' height='22' viewBox='0 0 32 32'>
                        <g fill='none' fillRule='evenodd'>
                          <g fill='#273042'>
                            <circle cx='7.5' cy='16.5' r='2.5' />
                            <circle cx='15.5' cy='16.5' r='2.5' />
                            <circle cx='23.5' cy='16.5' r='2.5' />
                          </g>
                        </g>
                      </svg>
                      <div>
                        <span onClick={() => deleteRequest(request._id)}>Delete</span>
                      </div>
                    </td>
                  ) : (
                    <td />
                  )}
                </RequestRow>
              ))}
          </tbody>
        </Table>
      </div>
    </SickDaysStyle>
  )
}

export default SickDays
