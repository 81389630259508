import React from 'react'
import xorBy from 'lodash/xorBy'

import {
  StyleBase,
  InputContainer,
  InputStyle,
  MembersList,
  MemberRow,
  Collaborator
} from './style'

class TeamAutoComplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newMember: '',
      members: props.value || [],
      availableMembers: props.members,
      sugguestionsShown: false,
      searchQuery: ''
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.closeSuggestions)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.closeSuggestions)
  }

  showSuggestions = () => {
    this.setState({ sugguestionsShown: true })
    this.inputQuery.focus()
  }

  closeSuggestions = e => {
    if (e.keyCode === 27) {
      this.setState({ sugguestionsShown: false })
      this.inputQuery.blur()
    }
  }

  onSearchQueryChange = e => {
    const searchQuery = e.target.value
    this.setState({ searchQuery })
  }

  onAddMember = _id => {
    const members = [
      ...this.state.members,
      this.state.availableMembers.find(x => x._id === _id)
    ]
    this.setState({
      members,
      availableMembers: this.props.members,
      searchQuery: ''
    }, () => {
      this.inputQuery.focus()
      this.props.onChange(members)
    })
  }

  manageCollabs = e => {
    const { keyCode } = e
    if (keyCode === 8 && !this.state.searchQuery) {
      const members = [...this.state.members]
      members.pop()
      this.setState({ members })
      this.props.onChange(members)
    }
  }

  onDeleteMember = _id => {
    const members = this.state.members.filter(x => x._id !== _id)
    this.setState({ members })
    this.props.onChange(members)
  }

  onSearchInputFocus = _ => {
    this.setState({ sugguestionsShown: true })
  }

  render() {
    const {
      members,
      sugguestionsShown,
      availableMembers,
      searchQuery
    } = this.state

    return (
      <StyleBase>
        <InputContainer onClick={this.showSuggestions}>
          {members.map((member, i) => (
            <Collaborator className={sugguestionsShown ? 'focused' : ''} key={`collabs-${i}`}>
              <img src={member.avatar} height={30} alt={member.name} />
              <span>{member.name}</span>
              {
                sugguestionsShown && <span onClick={() => this.onDeleteMember(member._id)} className='close-btn'>
                  <svg height={8} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.971 47.971">
                    <path d="M28.228 23.986L47.092 5.122a2.998 2.998 0 0 0 0-4.242 2.998 2.998 0 0 0-4.242 0L23.986 19.744 5.121.88a2.998 2.998 0 0 0-4.242 0 2.998 2.998 0 0 0 0 4.242l18.865 18.864L.879 42.85a2.998 2.998 0 1 0 4.242 4.241l18.865-18.864L42.85 47.091c.586.586 1.354.879 2.121.879s1.535-.293 2.121-.879a2.998 2.998 0 0 0 0-4.242L28.228 23.986z"/>
                  </svg>
                </span>
              }
            </Collaborator>
          ))}
          <div style={{ display: 'inline-block' }}>
            <InputStyle
              onFocus={this.onSearchInputFocus}
              ref={el => (this.inputQuery = el)}
              value={searchQuery}
              onChange={this.onSearchQueryChange}
              onKeyDown={this.manageCollabs}
            />
          </div>
        </InputContainer>

        {sugguestionsShown && (
          <MembersList ref={el => this.memberListSuggestion = el}>
            {xorBy(availableMembers, members, '_id')
              .filter(member =>
                member.name.toUpperCase().includes(searchQuery.toUpperCase())
              )
              .map((member, i) => (
                <MemberRow
                  onClick={() => this.onAddMember(member._id)}
                  key={`autocomplete-${i}`}>
                  <img src={member.avatar} height={30} alt={member.name} />
                  <span>{member.name}</span>
                </MemberRow>
              ))}
          </MembersList>
        )}
      </StyleBase>
    )
  }
}

TeamAutoComplete.defaultProps = {
  onChange: () => {},
  value: []
}

export default TeamAutoComplete
