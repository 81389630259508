import React, { Component, Fragment } from 'react'
import xorBy from 'lodash/xorBy'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getTeam, updateTeamMember, refreshTeam } from '../../../actions/team'

import { UsersListContainer, FiltersTab, RefreshBtn } from './style'

import UserCard from './userCard'
import Modal from '../../../components/Modal'
import UserDetails from './../userDetails'

class UsersList extends Component {
  state = {
    filter: 'employee',
    previewingUser: null,
    isModalShown: false
  }

  componentDidMount() {
    if (this.props.team.members.length === 0) {
      this.props.getTeam()
    }
  }

  toggleFilter = filter => {
    this.setState({ filter })
  }

  updateUsersList = (_id, update) => {
    this.setState({
      users: this.state.users.map(user => (user._id !== _id ? user : update))
    })
  }

  updateSelectedUser = user => {
    this.setState({
      previewingUser: user,
      isModalShown: !!user
    })
  }

  toggleModal = () => {
    const newState = {
      isModalShown: !this.state.isModalShown,
      previewingUser: this.state.isModalShown ? null : this.previewingUser
    }
    this.setState(newState)
  }

  render() {
    const { filter, isModalShown, previewingUser } = this.state
    const { user: currentMember, team } = this.props
    const { loading, error, members, refreshTeamOps } = team
    if (loading) {
      return '...loading'
    }
    if (error) {
      console.log(error)
      return 'Opps something went wrong'
    }

    const currentApprovers = !isModalShown
      ? null
      : previewingUser.approvers.map(id => members.find(x => x._id === id))
    const possibleApprovers = !isModalShown
      ? null
      : xorBy(
          members.filter(
            x =>
              x._id !== previewingUser._id &&
              (x.role === 'approver' ||
                x.role === 'admin' ||
                x.role === 'owner')
          ),
          currentApprovers,
          '_id'
        )
    return (
      <Fragment>
        <FiltersTab>
          <button
            onClick={() => this.toggleFilter('employee')}
            className={filter === 'employee' ? 'active' : ''}
          >
            Employees
          </button>
          <button
            onClick={() => this.toggleFilter('inactive')}
            className={filter === 'inactive' ? 'active' : ''}
          >
            Inactive members
          </button>
          <RefreshBtn
            loading={refreshTeamOps.loading}
            onClick={this.props.refreshTeam}
            className="refreshBtn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 477.867 477.867"
            >
              <path d="M409.6 0c-9.426 0-17.067 7.641-17.067 17.067v62.344C304.667-5.656 164.478-3.386 79.411 84.479a221.44 221.44 0 0 0-62.344 154.454c0 9.426 7.641 17.067 17.067 17.067S51.2 248.359 51.2 238.933c.021-103.682 84.088-187.717 187.771-187.696a187.734 187.734 0 0 1 138.442 60.976l-75.605 25.207c-8.954 2.979-13.799 12.652-10.82 21.606s12.652 13.799 21.606 10.82l102.4-34.133a17.069 17.069 0 0 0 11.674-16.247v-102.4C426.667 7.641 419.026 0 409.6 0zM443.733 221.867c-9.426 0-17.067 7.641-17.067 17.067-.021 103.682-84.088 187.717-187.771 187.696a187.734 187.734 0 0 1-138.442-60.976l75.605-25.207c8.954-2.979 13.799-12.652 10.82-21.606-2.979-8.954-12.652-13.799-21.606-10.82l-102.4 34.133a17.069 17.069 0 0 0-11.674 16.247v102.4c0 9.426 7.641 17.067 17.067 17.067s17.067-7.641 17.067-17.067v-62.345c87.866 85.067 228.056 82.798 313.122-5.068a221.44 221.44 0 0 0 62.344-154.454c.002-9.426-7.639-17.067-17.065-17.067z" />
            </svg>
            <span>Refresh</span>
          </RefreshBtn>
        </FiltersTab>
        <UsersListContainer>
          {members
            .filter(x =>
              filter === 'employee' ? x.is_employee : !x.is_employee
            )
            .map((member, i) => (
              <UserCard
                index={i}
                key={member._id}
                updateUsersList={this.props.updateTeamMember}
                currentMember={currentMember}
                selectMember={this.updateSelectedUser}
                member={member}
              />
            ))}
        </UsersListContainer>

        {isModalShown && (
          <Modal onClose={this.toggleModal} width={790}>
            <UserDetails
              closeModal={this.toggleModal}
              member={
                this.props.team.members.filter(
                  member => member._id === previewingUser._id
                )[0]
              }
              possibleApprovers={possibleApprovers}
              currentApprovers={currentApprovers}
            />
          </Modal>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = ({ team }) => ({ team })

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getTeam, updateTeamMember, refreshTeam },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersList)
