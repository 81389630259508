import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Home from '../views/home'
import Dashboard from './dashboard'
import Authentification from '../views/slackAuth'

import EnsureAuth from '../utils/EnsureAuth'

const uLeave = () => {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/slack/auth' component={Authentification} />
        <EnsureAuth path='/dashboard' component={Dashboard} />
      </Switch>
    </Router>
  )
}

export default uLeave
