import styled from 'styled-components'

export const StyleBase = styled.div`
  margin-top: 50px;
  border: 1px solid #DEDEDF;
`

export const Table = styled.div`
  border: 1px solid #DEDEDF;
  border-radius: 4px;
`

export const TableHeader = styled.div`
  display: flex;
  position: relative;
  background-color: #F5F6FA;
  padding: 18px 30px;
  span {
    width: 50%;
    text-transform: uppercase;
    color: #909090;
  }
  > div {
    display: flex;
    position: absolute;
    right: 30px;
    button {
      padding: 5px 15px;
      font-size: 12px;
    }
  }
`

export const HolidayRow = styled.div`
  display: flex;
  padding: 15px 30px;
  position: relative;
  span:not(:last-child) {
    width: 50%;
  }
  span {
    font-size: 14px;
  }
  &:nth-child(odd) {
    background-color: #FAF9FE;
  }
`

export const DeleteBtn = styled.span`
  position: absolute;
  right: 40px;
  color: #e74c3c;
  cursor: pointer;
`

export const HolidaySettings = styled.div`
  background-color: #FFF;
  border-radius: 4px;
  padding: 30px;
  h2 {
    margin: 0 0 30px;
  }
`

export const HolidayContainer = styled.form`
  display: flex;
  align-items: center;
  padding: 18px 30px;
  position: relative;
  label {
    margin-bottom: 5px;
    display: inline-block;
  }
  > div:first-child {
    margin: 0;
  }
  > div:not(:last-child) {
    width: 50%;
    input {
      max-width: 200px;
    }
  }
  > div:last-child {
    position: absolute;
    right: 30px;
    display: flex;
    button {
      padding: 5px 20px;
      &:first-child {
        color: #e74c3c;
      }
      &:last-child {
        color: #6050DC;
      }
    }
  }
  .DayPickerInput {
    display: block;
    input {
      display: block;
      padding: 6px 12px;
      font-size: 16px;
      line-height: 1.5;
      background-color: #fff;
      border: 1px solid #d9dadc;
      border-radius: 4px;
      height: 40px;
    }
  }
`
