import styled, { keyframes, css } from 'styled-components'

export const StyleBase = styled.div`
`

export const Header = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-left: 40px; 
  padding-right: 40px; 
  .user-details {
    margin-left: 20px;
    h2 {
      margin: 0;
    }
    span {
      color: hsl(0,0%,65%);
      font-size: 14px;
      letter-spacing: 0.5px;
    }
  }
`

export const Avatar = styled.div`
  position: relative;
  img {
    border-radius: 50%;
    height: 70px;
  }
  .avatar__tags {
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    text-align: center;
    span {
      border-radius: 4px;
      color: #FFF;
      font-size: .625rem;
      padding: 0 5px;
      text-transform: uppercase;
      display: inline-block;
    }
    span.tags__role {
      background-color: #6055d9;
    }
    span.tags__date {
      background-color: #fbc13b;
    }
  }
`

export const FormGroup = styled.div`
  margin-bottom: 10px;
  font-family: 'Roboto',sans-serif;
  label {
    font-weight: 400;
    font-size: 14px;
    position:relative;
  }
  .datepicker-styles label {
    bottom:8px !important;
  }
  Input label {
    margin-bottom:0px;
  }
  input {
    display: block;
    border: 1px solid #212240;
    font-weight: 400;
    outline: 0;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 7px;
    padding: 5px 10px;
    width: 100%;
    height:36px;
  }
  p {
    margin: -8px 0 10px;
    font-size: 12px;
    color: #909090;
    line-height:24px;
  }
  .date-helper {
    margin: 9px 0 10px !important;
    line-height: 17px !important;
  }
  input.error {
    border-color: #E23943;
  }
  .error {
    input {
      border-color: #E23943;
    }
  }
`

export const InlineForm = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 720px) {
    flex-direction: column;
  }
  > div {
    width: 49%;
    height:120px;
    @media(max-width: 720px){
      width: 100%;
    }
  }
  .DayPickerInput {
    display: block;
  }
`

export const LeaveBalance = styled.div`
  display: flex;
  align-items: center;
  margin-top: 7px;
  .decrease-balance {
    font-size: 0;
    cursor: pointer;
    &:before {
      content: '';
      height: 3px;
      width: 15px;
      background-color: #212240;
      display: block;
      border-radius: 4px;
    }
  }
  .increase-balance {
    font-size: 0;
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      height: 3px;
      width: 15px;
      background-color: #212240;
      display: block;
      border-radius: 4px;
    }
    &:after {
      content: '';
      height: 15px;
      width: 3px;
      background-color: #212240;
      display: block;
      border-radius: 4px;
      position: absolute;
      top: -6px;
      left: 6px;
    }
  }
  input {
    border: 1px solid #212240;
    font-weight: 400;
    padding: 0;
    margin: 0 10px;
    outline: 0;
    border-radius: 3px;
    text-align: center;
    height: 28px;
    width: 46px;
    font-size: 14px;
  }
`

export const Approvers = styled.div`
  display: flex;
  margin-top: 7px;
  .approvers-list, .sugg-approvers-list {
    display: flex;
    div {
      margin-right: 5px;
      cursor: pointer;
      position: relative;
      span {
        position: absolute;
        top: 55px;
        left: 0;
        font-size: 13px;
        background-color: rgba(37, 36, 36, .9);
        color: #FFF;
        border-radius: 4px;
        padding: 5px 10px;
        white-space: nowrap;
        left: 50%;
        opacity: 0;
        pointer-events: none;
        transition: all .2s ease-in-out;
        transform: translateX(-50%) scale3d(.2,.2,1);
        &:before {
          content: '';
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid rgba(37,36,36,.9);
          position: absolute;
          top: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &:hover  span {
        opacity: 1;
        transform: translateX(-50%) scale3d(1,1,1);
      }
    }
  }
  .add-approver {
    background-color: #604adf;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: .15s all ease;
    margin-right: 5px;
    &.close {
      transform: rotate(45deg)
    }
  }
  .add-approver:before,
  .add-approver:after {
    content: '';
    position: absolute;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 12px;
    background-color:#fff;
  }
  .add-approver:before{
    width: 2px;
    margin: 2px auto;
  }
  .add-approver:after{
    margin: auto 2px;
    height: 2px;
  }
  > div {
    img {
      height: 45px;
      border-radius: 50%;
    }
  }
`

const SlideLeft = keyframes`
  0% {
    transform: translate3d(-10px, 0, 0);
    opacity:0
  }
  to {
    transform: translateZ(0);
    opacity: 1
  }
`

const FadeIn = keyframes`
  0% {
    transform: scale3d(.2,.2,1);
    opacity:0
  }
  to {
    transform: scale3d(1,1,1);
    opacity: 1
  }
`

export const ApproverProfile = styled.div`
  transform: translate3d(-10px, 0, 0);
  opacity: 0;
  position: relative;
  animation-delay: ${props => props.delay}ms;
  animation-name: ${SlideLeft};
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-duration: .5s;
  ${props => props.removed && css`
    &:after {
      content: '';
      background-image: url('data:image/svg+xml;utf8,<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#D9393B" cx="10" cy="10" r="10"/><rect fill="#FFF" x="4" y="9" width="12" height="2" rx="1"/></g></svg>');
      height: 20px;
      width: 20px;
      display: block;
      position: absolute;
      right: -6px;
      top: -6px;
      z-index: 999;
      background-size: cover;
      background-color: white;
      border-radius: 50%;
      border: 2px solid #fff;

      opacity: 0;
      transform: scale3d(.2,.2,1);
      animation-name: ${FadeIn};
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-duration: .15s;
    }
  `}
  ${props => props.added && css`
    &:after {
      content: '';
      background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0MjYuNjY3IDQyNi42NjciIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQyNi42NjcgNDI2LjY2NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI1MTJweCIgaGVpZ2h0PSI1MTJweCI+CjxwYXRoIHN0eWxlPSJmaWxsOiM2QUMyNTk7IiBkPSJNMjEzLjMzMywwQzk1LjUxOCwwLDAsOTUuNTE0LDAsMjEzLjMzM3M5NS41MTgsMjEzLjMzMywyMTMuMzMzLDIxMy4zMzMgIGMxMTcuODI4LDAsMjEzLjMzMy05NS41MTQsMjEzLjMzMy0yMTMuMzMzUzMzMS4xNTcsMCwyMTMuMzMzLDB6IE0xNzQuMTk5LDMyMi45MThsLTkzLjkzNS05My45MzFsMzEuMzA5LTMxLjMwOWw2Mi42MjYsNjIuNjIyICBsMTQwLjg5NC0xNDAuODk4bDMxLjMwOSwzMS4zMDlMMTc0LjE5OSwzMjIuOTE4eiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
      height: 20px;
      width: 20px;
      display: block;
      position: absolute;
      right: -6px;
      top: -6px;
      z-index: 999;
      background-size: cover;
      background-color: white;
      border-radius: 50%;
      border: 2px solid #fff;

      opacity: 0;
      transform: scale3d(.2,.2,1);
      animation-name: ${FadeIn};
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-duration: .15s;
    }
  `}
`

export const GroupBtn = styled.div`
  text-align: right;
  background-color: #f9f9f9;
  padding: 15px 40px;
  border-radius: 0 0 4px 4px;
`


export const Button = styled.button`
  font-weight: 500;
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: all .15s ease;
  ${props => props.primary && css`
    color: #FFFFFF;
    background-color: #6055d9;
    border: 1px solid #6055d9;
  `}
  ${props => props.borderless && css`
    color: #212240;
    background-color: transparent;
    border: 0;
  `}
`

export const UserBio = styled.div`
  textarea {
    border: 1px solid #212240;
    font-weight: 400;
    outline: 0;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 7px;
    padding: 5px 10px;
    width: 100%;
  }
`

export const AboutSection = styled.div`
  padding-left: 40px; 
  padding-right: 40px; 
`

export const LeaveManagement = styled.div`
  padding-left: 40px; 
  padding-right: 40px;
`

export const SickDaysStyle = styled.div`
  padding-left: 40px; 
  padding-right: 40px;
  margin-top: 5px;
  margin-bottom: 20px;
  ${FormGroup} {
    display: flex;
    button {
      padding: 10px;
    }
    input {
      max-width: 140px;
      margin-right: 10px;
    }
  }
  label {
      font-size:14px;
    }
  .leave-history-p {
      position: relative;
      top:12px;
      font-size: 14px;
      font-weight: 400;
    }
    .leave-history-table {
      margin-top: 20px;
    }
`

export const Table = styled.table`
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
  thead {
    background-color: #F5F6FA;
    border: 1px solid #DEDEDE;
    td {
      color: #909090;
      padding: 10px 15px;
    }
  }
`

export const RequestRow = styled.tr`
  position: relative;
  border: 1px solid #DEDEDE;
  font-size: 14px;
  &:nth-child(even) {
    background-color: #FAF9FE;
  }
  &:hover td:last-child {
    opacity: 1;
  }
  td {
    padding: 10px 15px;
    &:nth-child(2) {
      width: 340px;
    }
  }
  td:last-child {
    opacity: 0;
    width: 50px;
    position: relative;
    cursor: pointer;
    &:hover > div {
      display: block;
    }
    > div {
      position: absolute;
      z-index: 99;
      background-color: rgb(255, 255, 255);
      box-shadow: rgba(99, 114, 130, 0.16) 0px 0px 0px 1px, rgba(27, 39, 51, 0.08) 0px 8px 16px;
      right: 15px;
      width: 120px;
      border-radius: 4px;
      padding: 5px 0px;
      display: none;
      span {
        font-size: 14px;
        display: block;
        width: 100%;
        cursor: pointer;
        padding: 8px 20px;
        &:hover {
          background-color: rgba(226, 57, 67, 0.1);
        }
      }
    }
  }
`

const typeStyles = {
  leave: css`
    background-color: #fdedd3;
    color: #F5A623;
  `,
  sick: css`
    background-color: #dbe9f9;
    color: #698cb5;
  `
}

export const RequestType = styled.span`
  border-radius: 50px;
  padding: 2px 10px;
  font-size: 14px;
  ${props => typeStyles[props.type]};
`
