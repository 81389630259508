import {
  GET_TEAM,
  GET_TEAM_ERROR,
  UPDATE_MEMBER,
  REFRESH_TEAM,
  REFRESH_TEAM_ERROR,
  REFRESH_TEAM_SUCCESS
} from '../constants'

export const getTeam = () => {
  return async dispatch => {
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/users', {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('Authorization')
      }
    })
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: GET_TEAM,
        payload: body
      })
    } else {
      dispatch({
        type: GET_TEAM_ERROR,
        payload: body
      })
    }
  }
}

export const refreshTeam = () => {
  return async dispatch => {
    dispatch({ type: REFRESH_TEAM })
    const response = await fetch(
      process.env.REACT_APP_API_URL + '/api/users/refresh',
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('Authorization')
        }
      }
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: REFRESH_TEAM_SUCCESS,
        payload: body
      })
    } else {
      dispatch({
        type: REFRESH_TEAM_ERROR,
        payload: body
      })
    }
  }
}

export const updateTeamMember = member => {
  return {
    type: UPDATE_MEMBER,
    payload: member
  }
}
