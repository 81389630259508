import React from 'react'

import { StyleBase, Header } from './style'

import UsersList from './usersList'

const ManageTeam = ({ user }) => {
  return (
    <StyleBase>
      <Header>
        <h1>Manage Team</h1>
        <p>Here you can modify users leave allowance settings. New users will not have access to uLeave through Slack until you confirm their settings here and click "Save Changes".</p>
      </Header>
      <UsersList user={user} />
    </StyleBase>
  )
}

export default ManageTeam
