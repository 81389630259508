import styled from 'styled-components'

export const StyleBase = styled.div`
  padding: 10px 40px;
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 10px 0 30px rgba(0,0,0,.05);
`

export const LogoLink = styled.div`
  text-align: center;
`

export const Navigation = styled.ul`
  list-style: none;
  padding: 10px 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  li a {
    text-decoration: none;
    color: inherit;
    font-size: 15px;
    margin-right: 60px;
    border-bottom: 4px solid transparent;
    font-weight: 500;
    color: hsl(0, 3%, 47%);
    transition: color .15s ease-in;
    &.active {
      color: hsl(247, 67%, 59%);
    }
    &:not(.active):hover {
      color: hsl(236, 31%, 19%);
    }
  }
`

export const LogoutBtn = styled.div`
  text-align: center;
  cursor: pointer;
  a {
    color: inherit;
  }
`
