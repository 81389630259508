import styled from 'styled-components'

export const StyleBase = styled.div`
  text-align: center;
  margin-top: 100px;
  a {
    color: #6050DC;
  }
  .slack-button {
    background: url("/static/media/slack-logo.svg") 20px center no-repeat #FFF;
    background-size: auto 50%;
    border: 1px solid rgba(99,106,137,.5);
    border-radius: 40px;
    color: rgba(99,106,137,.5);
    display: inline-block;
    padding: 12px 30px 12px 60px;
    margin-top: 1.5em;
    white-space: nowrap;
  }
  h1 {
    margin-top: 1.5em;
    margin-bottom: .3em;
  }
  p span {
    font-style: italic;
    line-height: 1.9;
  }
`
