import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getCompanies } from '../../actions/companies'

import { StyleBase, Header, CompaniesList } from './style'

import CompanyCard from './companyCard'
import Button from './../../components/Button'

class AccountDetails extends Component {
  componentDidMount () {
    // fetch from redux store first
    if (this.props.companies.list.length === 0 || this.props.companies.loading) {
      this.props.getCompanies()
    }
  }

  render () {
    const { companies } = this.props
    const { loading, error } = companies
    if (loading) {
      return 'loading...'
    }
    if (error) {
      console.log(error)
      return 'Opps something went wrong'
    }

    return (
      <StyleBase>
        <Header>
          <div>
            <h1>Companies</h1>
            <p>
              Here you can add and edit companies that Gemography work with.
            </p>
          </div>
          <Link to='/dashboard/companies/new'>
            <Button btnStyle='primary'>Add new</Button>
          </Link>
        </Header>
        <CompaniesList>
          {companies.list.map(company => (
            <Link
key={company._id} to={{
              pathname: `/dashboard/companies/${company._id}`
            }}
            >
              <CompanyCard {...company} team={company.team.filter(member => !member.left_at)} />
            </Link>
          ))}
        </CompaniesList>
      </StyleBase>
    )
  }
}

const mapStateToProps = ({ companies }) => ({ companies })

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getCompanies }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
