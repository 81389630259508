import React, { Component, Fragment } from 'react'

import { UserCardStyle, Avatar, Tdots, Options } from './style'

class UserCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      optionsShown: false
    }
  }

  toggleOptions = (e) => {
    e.stopPropagation()
    this.setState({ optionsShown: !this.state.optionsShown })
  }

  onOutsideClick = (e) => {
    if (
      this.state.optionsShown &&
      this.wrapperRef &&
      !this.wrapperRef.contains(e.target)
    ) {
      this.toggleOptions(e)
    }
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.onOutsideClick)
  }
  componentWillUnmount () {
    document.removeEventListener('mousedown', this.onOutsideClick)
  }

  updateUserProfile = async (e, {id, ...reqBody}) => {
    e.stopPropagation()
    // TODO: handle errors
    // TODO: show toast after successfuly updating the role
    console.log(reqBody)
    try {
      const response = await window.fetch(`${process.env.REACT_APP_API_URL}/api/users/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('Authorization')
        },
        body: JSON.stringify(reqBody)
      })
      const body = await response.json()
      if (body.ok) {
        this.setState({
          optionsShown: false
        }, () => {
          this.props.updateUsersList(body.data)
        })
      } else {
        console.log(body)
      }
    } catch (error) {
      console.log(error)
    }
  }

  render () {
    const { optionsShown } = this.state
    const { currentMember, index, member, selectMember } = this.props
    return (
      <UserCardStyle hasStartDate={!!member.start_date} onClick={() => selectMember(member)} delay={20 * (4 * index + 1)}>
        {
          currentMember._id !== member._id && member.role !== 'owner' && (
            <Fragment>
              <Tdots shown={optionsShown} onClick={this.toggleOptions} >
                <svg width="32" height="32" viewBox="0 0 32 32">
                  <g fill="none" fillRule="evenodd">
                    <g fill="#273042">
                      <circle cx="16.5" cy="10.5" r="1.5"></circle>
                      <circle cx="16.5" cy="15.5" r="1.5"></circle>
                      <circle cx="16.5" cy="20.5" r="1.5"></circle>
                    </g>
                  </g>
                </svg>
              </Tdots>
              <Options
                onClick={this.onOutsideClick}
                ref={this.setWrapperRef}
                shown={optionsShown} >
                <ul>
                  <li onClick={(e) => this.updateUserProfile(e, { id: member._id, role: member.role === 'approver' ? 'user' : 'approver' })}>
                    <span>{
                      member.role === 'approver' && member.role !== 'admin'
                        ? 'Remove Approving right'
                        : 'Set as an Approver'
                    }</span>
                  </li>
                  <li onClick={(e) => this.updateUserProfile(e, { id: member._id, role: member.role === 'admin' ? 'user' : 'admin' })}>
                    <span>{
                      member.role === 'admin' && member.role !== 'approver'
                        ? 'Remove Admin right'
                        : 'Set as an Admin'
                    }</span>
                  </li>
                  <li onClick={(e) => this.updateUserProfile(e, { id: member._id, is_employee: !member.is_employee })}>
                    <span>{member.is_employee
                      ? 'Mark as inactive'
                      : 'Mark as employee'
                    }</span>
                  </li>
                  <li onClick={(e) => this.updateUserProfile(e, { id: member._id, remote_allowed: !member.remote_allowed })}>
                    <span>{
                      member.remote_allowed
                      ? 'Disallow Remote'
                      : 'Allow Remote'
                    }
                    </span>
                  </li>
                </ul>
              </Options>
            </Fragment>
          )
        }
        <Avatar>
          <img src={member.avatar} alt={member.name} />
          {
            (member.role !== 'user' || !member.start_date) && (
              <div className="avatar__tags">
                {member.role !== 'user' && <span className="tags__role">{member.role}</span> }
                {!member.start_date && <span className="tags__date">Missing date</span> }
              </div>
            )
          }
        </Avatar>
        <h3>{member.name}</h3>
        <span>{member.email}</span>
        {
          !member.start_date && (
            <button className="set-date">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 488.152 488.152">
                <g fill="#FFF">
                  <path d="M177.854 269.311c0-6.115-4.96-11.069-11.08-11.069h-38.665c-6.113 0-11.074 4.954-11.074 11.069v38.66c0 6.123 4.961 11.079 11.074 11.079h38.665c6.12 0 11.08-4.956 11.08-11.079v-38.66zM274.483 269.311c0-6.115-4.961-11.069-11.069-11.069h-38.67c-6.113 0-11.074 4.954-11.074 11.069v38.66c0 6.123 4.961 11.079 11.074 11.079h38.67c6.108 0 11.069-4.956 11.069-11.079v-38.66zM371.117 269.311c0-6.115-4.961-11.069-11.074-11.069h-38.665c-6.12 0-11.08 4.954-11.08 11.069v38.66c0 6.123 4.96 11.079 11.08 11.079h38.665c6.113 0 11.074-4.956 11.074-11.079v-38.66zM177.854 365.95c0-6.125-4.96-11.075-11.08-11.075h-38.665c-6.113 0-11.074 4.95-11.074 11.075v38.653c0 6.119 4.961 11.074 11.074 11.074h38.665c6.12 0 11.08-4.956 11.08-11.074V365.95zM274.483 365.95c0-6.125-4.961-11.075-11.069-11.075h-38.67c-6.113 0-11.074 4.95-11.074 11.075v38.653c0 6.119 4.961 11.074 11.074 11.074h38.67c6.108 0 11.069-4.956 11.069-11.074V365.95zM371.117 365.95c0-6.125-4.961-11.075-11.069-11.075h-38.67c-6.12 0-11.08 4.95-11.08 11.075v38.653c0 6.119 4.96 11.074 11.08 11.074h38.67c6.108 0 11.069-4.956 11.069-11.074V365.95z"/>
                  <path d="M440.254 54.354v59.05c0 26.69-21.652 48.198-48.338 48.198h-30.493c-26.688 0-48.627-21.508-48.627-48.198V54.142h-137.44v59.262c0 26.69-21.938 48.198-48.622 48.198H96.235c-26.685 0-48.336-21.508-48.336-48.198v-59.05c-23.323.703-42.488 20.002-42.488 43.723v346.061c0 24.167 19.588 44.015 43.755 44.015h389.82c24.131 0 43.755-19.889 43.755-44.015V98.077c0-23.721-19.164-43.02-42.487-43.723zm-14.163 368.234c0 10.444-8.468 18.917-18.916 18.917H80.144c-10.448 0-18.916-8.473-18.916-18.917V243.835c0-10.448 8.467-18.921 18.916-18.921h327.03c10.448 0 18.916 8.473 18.916 18.921l.001 178.753z"/>
                  <path d="M96.128 129.945h30.162c9.155 0 16.578-7.412 16.578-16.567V16.573C142.868 7.417 135.445 0 126.29 0H96.128C86.972 0 79.55 7.417 79.55 16.573v96.805c0 9.155 7.422 16.567 16.578 16.567zM361.035 129.945h30.162c9.149 0 16.572-7.412 16.572-16.567V16.573C407.77 7.417 400.347 0 391.197 0h-30.162c-9.154 0-16.577 7.417-16.577 16.573v96.805c0 9.155 7.423 16.567 16.577 16.567z"/>
                </g>
              </svg>
              <span>Set date</span>
            </button>
          )
        }
      </UserCardStyle>
    )
  }
}

export default UserCard
