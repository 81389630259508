import styled, { keyframes, css } from 'styled-components'

export const UsersListContainer = styled.div`
  margin: 50px 0;
  display: grid;
	grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
	grid-gap: 2.5rem 1.5rem;
`


const Mic = keyframes`
  0% {
    transform: translate3d(0,-4px,0);
    opacity:0
  }
  to {
    transform: translateZ(0);
    opacity: 1
  }
`

export const UserCardStyle = styled.div`
  height: 217px;
  box-shadow: 0 15px 35px 0 rgba(42,51,83,.12), 0 5px 15px rgba(0,0,0,.06);
  background-color: #FFF;
  text-align: center;
  padding: 40px 20px 0;
  border-radius: 6px;
  line-height: 1.6;
  cursor: pointer;
  transition: all .15s ease;
  position: relative;
  transform: translate3d(0, -4px, 0);
  opacity: 0;
  animation-delay: ${props => props.delay}ms;
  animation-name: ${Mic};
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  /* overflow: hidden; */
  h3 {
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 0;
  }
  > span {
    color: hsl(0, 0%, 65%);
    font-size: 13px;
    letter-spacing: 0.5px;
  }
  .set-date {
    font-weight: 400;
    font-size: 14px;
    background-color: #6055d9;
    padding: 10px 40px;
    border-radius: 50px;
    color: #FFFFFF;
    cursor: pointer;
    border: 1px solid #6055d9;
    display: block;
    margin: 10px auto 0;
    transition: all .15s ease;
    transform: translateY(20px);
    opacity: 0;
    display: flex;
    align-items: center;
    span {
      margin-left: 7px;
    }
  }
  :hover {
    > div:first-child {
      opacity: 1;
    }
    ${props => !props.hasStartDate && css`
      padding-top: 15px;
      .set-date {
        transform: translateY(0);
        opacity: 1;
      }
    `}
  }
`

export const Avatar = styled.div`
  position: relative;
  img {
    border-radius: 50%;
    height: 60px;
  }
  .avatar__tags {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    span {
      border-radius: 4px;
      color: #FFF;
      font-size: .625rem;
      padding: 0 5px;
      text-transform: uppercase;
      display: inline-block;
    }
    span.tags__role {
      background-color: #6055d9;
    }
    span.tags__date {
      background-color: #fbc13b;
    }
  }
`

export const Tdots = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: ${props => props.shown ? 1 : 0};
  z-index: 100;
`

export const Options = styled.div`
  display: ${props => props.shown ? 'block' : 'none'};
  text-align: left;
  position: absolute;
  top: 50px;
  right: 10px;
  z-index: 99;
  width: 180px;
  border: 1px solid hsla(0, 0%, 0%, .2);
  border-radius: 4px;
  background-color: #FFF;
  ul {
    list-style: none;
    padding: 10px 0;
    margin: 0;
    li {
      padding: 5px 20px;
      margin-bottom: 5px;
      font-size: 13px;
      color: hsl(0, 0%, 30%);
      &:hover {
        background-color: hsla(0, 0%, 70%, .3);
        color: #6055d9;
      }
    }
  }
`

const scaleXIn = keyframes`
  0% { transform: scaleX(0) }
  to { transform: scaleX(1) }
`

const spin = keyframes`
  0% { transform: rotate(0) }
  to { transform: rotate(360deg) }
`

export const RefreshBtn = styled.button`
  background-color: transparent;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  color: #21243e;
  display: flex;
  align-items: center;
  margin-left: auto;
  opacity: .4;
  transition: .15s opacity ease-in;
  cursor: pointer;
  &:hover {
    opacity: 1;
    svg {
      /* transform: rotate(180deg) */
    }
  }
  &:focus {
    outline: 0
  }
  svg {
    height: 18px;
    margin-right: 10px;
    transition: .5s transform ease-in;
    ${props => props.loading && css`
      animation: ${spin} .5s infinite;
    `};
  }
`

export const FiltersTab = styled.header`
  margin-top: 50px;
  display: flex;
  button:not(.refreshBtn) {
    font-size: 1.1em;
    font-weight: 600;
    color: #21243e;
    background-color: transparent;
    border: 0;
    position: relative;
    cursor: pointer;
    outline: 0;
    &:first-child {
      margin-right: 10px;
    }
    &.active::after {
      content: '';
      position: absolute;
      top: 30px;
      left: 7px;
      right: 7px;
      display: block;
      height: 3px;
      background-color: #21243e;
      transform: scaleX(0);
      animation: ${scaleXIn} .2s;
      animation-fill-mode: forwards;
    }
    &:not(.active) {
      opacity: .4;
    }
  }
`
