import {
  GET_COMPANIES,
  GET_COMPANIES_ERROR,
  CREATE_COMPANY,
  CREATE_COMPANY_ERROR,
  CREATE_COMPANY_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ERROR,
  UPDATE_COMPANY_SUCCESS
} from '../constants'

export const getCompanies = () => {
  return async dispatch => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/companies`,
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('Authorization')
        }
      }
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: GET_COMPANIES,
        payload: body
      })
    } else {
      dispatch({
        type: GET_COMPANIES_ERROR,
        payload: body
      })
    }
  }
}

export const createCompany = params => {
  return async (dispatch, getState) => {
    dispatch({ type: CREATE_COMPANY })
    const { team } = getState()
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/companies`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      }
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: CREATE_COMPANY_SUCCESS,
        payload: {
          ...body,
          company: {
            ...body.company,
            team: body.company.team.map(x => ({
              ...x,
              // sorry.
              // when getting the response from the API the value company.team[].member
              // is always an id therefore we need to populate with the team we have
              // in the redux store.
              member: team.members.find(member => member._id === x.member)
            }))
          }
        }
      })
    } else {
      dispatch({
        type: CREATE_COMPANY_ERROR,
        payload: body
      })
    }
  }
}

export const updateCompany = (params, id) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_COMPANY })
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/companies/${id}`,
      {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      }
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: UPDATE_COMPANY_SUCCESS,
        payload: body
      })
    } else {
      dispatch({
        type: UPDATE_COMPANY_ERROR,
        payload: body
      })
    }
  }
}
