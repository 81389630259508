import {
  GET_HOLIDAYS,
  GET_HOLIDAYS_FAILED,
  ADD_HOLIDAY,
  ADD_HOLIDAY_SUCCESS,
  ADD_HOLIDAY_ERROR,
  DELETE_HOLIDAY,
  DELETE_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_ERROR
} from '../constants'

export const getHolidays = _ => {
  return async dispatch => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/holidays`
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: GET_HOLIDAYS,
        payload: body
      })
    } else {
      dispatch({
        type: GET_HOLIDAYS_FAILED,
        payload: body
      })
    }
  }
}

export const addHoliday = params => {
  return async dispatch => {
    dispatch({ type: ADD_HOLIDAY })
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/holidays`,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      }
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: ADD_HOLIDAY_SUCCESS,
        payload: body
      })
    } else {
      dispatch({
        type: ADD_HOLIDAY_ERROR,
        payload: body
      })
    }
  }
}

export const deleteHoliday = id => {
  return async dispatch => {
    dispatch({ type: DELETE_HOLIDAY })
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/holidays/${id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
          'Content-Type': 'application/json'
        }
      }
    )
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: DELETE_HOLIDAY_SUCCESS,
        payload: body
      })
    } else {
      dispatch({
        type: DELETE_HOLIDAY_ERROR,
        payload: body
      })
    }
  }
}