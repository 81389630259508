import styled from 'styled-components'

export const StyleBase = styled.div`
  padding: 30px 0;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    margin: 0;
  }
  p {
    color: #aba6c2;
  }
  button {
    max-width: 140px;
  }
`

export const CompaniesList = styled.div`
  margin-top: 40px;
  a {
    color: inherit;
    text-decoration: none;
  }
`
