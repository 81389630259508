import styled from 'styled-components'

export const StyleBase = styled.div`
  max-width: 540px;
  padding: 0 20px;
  margin: 100px auto;
  color: #767676;
  svg {
    display: block;
    margin: 0 auto 70px;
  }
  h2 {
    color: #273042;
    font-weight: 700;
    font-size: 2rem
  }
  p {
    margin: 10px 0 20px;
  }
`
