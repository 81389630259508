import {
  GET_COMPANIES,
  GET_COMPANIES_ERROR,
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_ERROR,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR
} from '../constants'

const intitialState = {
  list: [],
  error: null,
  loading: true,
  newCompany: {
    loading: false,
    error: null
  },
  editCompany: {
    loading: false,
    error: null
  }
}

export default function companies(state = intitialState, action) {
  const { type, payload } = action
  switch (type) {
    case GET_COMPANIES:
      return {
        ...state,
        loading: false,
        list: payload.companies,
        error: null
      }

    case GET_COMPANIES_ERROR:
      return {
        ...state,
        loading: false,
        error: payload.error
      }

    case CREATE_COMPANY:
      return {
        ...state,
        newCompany: {
          loading: true,
          error: null
        }
      }

    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        list: [...state.list, payload.company],
        newCompany: {
          loading: false,
          error: null
        }
      }

    case CREATE_COMPANY_ERROR:
      return {
        ...state,
        newCompany: {
          loading: false,
          error: payload.error,
        }
      }

    case UPDATE_COMPANY:
      return {
        ...state,
        editCompany: {
          loading: true,
          error: null
        }
      }

    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        list: state.list.map(company =>
          company._id === payload.company._id ? payload.company : company
        ),
        editCompany: {
          loading: false,
          error: null
        }
      }

    case UPDATE_COMPANY_ERROR:
      return {
        ...state,
        editCompany: {
          loading: false,
          error: payload.error
        }
      }

    default:
      return state
  }
}
