export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS'
export const USER_SIGN_IN_ERROR = 'USER_SIGN_IN_ERROR'
export const USER_SIGN_OUT = 'USER_SIGN_OUT'

export const GET_TEAM = 'GET_TEAM'
export const GET_TEAM_ERROR = 'GET_TEAM_ERROR'

export const REFRESH_TEAM = 'REFRESH_TEAM'
export const REFRESH_TEAM_SUCCESS = 'REFRESH_TEAM_SUCCESS'
export const REFRESH_TEAM_ERROR = 'REFRESH_TEAM_ERROR'

export const UPDATE_MEMBER = 'UPDATE_MEMBER'

export const GET_COMPANIES = 'GET_COMPANIES'
export const GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR'

export const CREATE_COMPANY = 'CREATE_COMPANY'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_ERROR = 'CREATE_COMPANY_ERROR'

export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_ERROR = 'UPDATE_COMPANY_ERROR'

export const GET_HOLIDAYS = 'GET_HOLIDAYS'
export const GET_HOLIDAYS_FAILED = 'GET_HOLIDAYS_FAILED'

export const ADD_HOLIDAY = 'ADD_HOLIDAY'
export const ADD_HOLIDAY_SUCCESS = 'ADD_HOLIDAY_SUCCESS'
export const ADD_HOLIDAY_ERROR = 'ADD_HOLIDAY_ERROR'

export const DELETE_HOLIDAY = 'DELETE_HOLIDAY'
export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS'
export const DELETE_HOLIDAY_ERROR = 'DELETE_HOLIDAY_ERROR'

export const GET_REQUESTS = 'GET_REQUESTS'
export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS'
export const GET_REQUESTS_ERROR = 'GET_REQUESTS_ERROR'

export const ADD_SICK_REQUEST = 'ADD_SICK_REQUEST' 
export const ADD_SICK_REQUEST_SUCCESS = 'ADD_SICK_REQUEST_SUCCESS' 
export const ADD_SICK_REQUEST_ERROR = 'ADD_SICK_REQUEST_ERROR' 

export const DELETE_SICK_REQUEST = 'DELETE_SICK_REQUEST' 
export const DELETE_SICK_REQUEST_SUCCESS = 'DELETE_SICK_REQUEST_SUCCESS' 
export const DELETE_SICK_REQUEST_ERROR = 'DELETE_SICK_REQUEST_ERROR' 
