import React from 'react'
import { StyleBase, InputStyle, TextAreaStyle, Label, ErrorMsg } from './style'

const Input = ({ label, id, textarea, error, ...props }) => {
  return (
    <StyleBase hasError={!!error}>
      {label && <Label htmlFor={id}>{label}</Label>}
      {textarea ? (
        <TextAreaStyle id={id} {...props} />
      ) : (
        <InputStyle id={id} {...props} />
      )}
      {!!error && <ErrorMsg>{error}</ErrorMsg>}
    </StyleBase>
  )
}

export default Input
