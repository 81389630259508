import styled from 'styled-components'

export const StyleBase = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .8);
  z-index: 99;
  top: 0;
  overflow-y: scroll;
  .modal-appear {
    opacity: 0.01; }
  .modal-appear > div {
    opacity: 0;
  }
  .modal-appear.modal-appear-active {
    opacity: 1;
    transition: opacity 150ms ease; }
`

export const ModalContent = styled.div`
  ${(props) => props.width ? `max-width: ${props.width}px` : null};
  background-color: #FFFFFF;
  /* padding: 30px 40px 25px; */
  padding-top: 30px;
  border-radius: 4px;
  margin: 100px auto;
  width: 100%;
  transition: opacity 0.09s ease;
  position: relative;
  z-index: 101;
`

export const CloseBtn = styled.button`
  position: absolute;
  right: 40px;
  top: 30px;
  cursor: pointer;
  border: 0;
  background-color: transparent;
`
