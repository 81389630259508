import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getCompanies, updateCompany } from '../../actions/companies'
import { getTeam } from '../../actions/team'
import CompanyForm from '../../components/CompanyForm'

class ViewCompany extends Component {
  state = {
    companyDetails: null,
    error: null
  }
  componentDidMount() {
    const {
      team, getTeam, getCompanies
    } = this.props
    if (team.members.length === 0) {
      getTeam()
    }
    getCompanies()
  }

  componentWillReceiveProps(nextProps) {
    const { companies, error, match } = nextProps
    if (error) {
      this.setState({ error: 'Something went wrong' })
    }
    const companyDetails = companies.list.find(
      company => company._id === match.params.id
    )
    if (!companyDetails) {
      this.setState({ error: 'Could not find the requested company' })
    } else {
      this.setState({ companyDetails: {
        ...companyDetails,
        team: companyDetails.team.filter(member => !member.left_at)
      }})
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.editCompany.loading && !this.props.editCompany.error) {
      this.props.history.push('/dashboard/companies')
    }
  }

  render() {
    const { companyDetails } = this.state
    const { team: { members, loading }, editCompany } = this.props
    if (loading) {
      return null
    }

    return !companyDetails ? null : (
      <div>
        <CompanyForm
          companyDetails={companyDetails}
          members={members}
          updateCompany={(params) => this.props.updateCompany(params, companyDetails.id)}
          editCompany={editCompany}
          method="update" />
      </div>
    )
  }
}

const mapStateToProps = ({ companies, team }) => ({ companies, team, editCompany: companies.editCompany })

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getCompanies, updateCompany, getTeam }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewCompany)
