import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #dadcde;
  margin-bottom: 30px;
`

export const StyleBase = styled.div`
  margin: 30px 0 40px;
  h1 {
    margin-bottom: 50px;
  }
`


export const Overview = styled.div`
  width: 30%;
  h4 {
    margin: 0;
  }
  p {
    margin-top: 5px;
    color: #909092;
  }
`

export const Form = styled.div`
  width: calc(70% - 30px);
  margin-left: 30px;
`

export const ButtonContainer = styled.div`
  max-width: 200px;
  margin-left: auto;
`

export const ErrorMessage = styled.div`
  font-size: 14px;
  color: #e6903a;
`
