import styled from 'styled-components'

export const StyleBase = styled.div`
  margin-bottom: 30px;
  background-color: #FFF;
  box-shadow: 0 15px 35px 0 rgba(42,51,83,.12), 0 5px 15px rgba(0,0,0,.06);
  border-radius: 6px;
  padding: 30px 40px;
  transition: transform .15s ease;
  display: flex;
  align-items: center;
  &:hover {
    transform: translateY(-3px)
  }
  > img {
    max-width: 120px;
    width: 100%;
    height: 100%;
  }
  > div {
    margin-left: 40px;
  }
  h3 {
    margin-top: 0;
    text-decoration: underline;
  }
`
export const ProfilePic = styled.div`
  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 3px solid #FFF;
    display: block;
    margin-left: -11px;
  }
`

export const MembersList = styled.div`
  display: flex;
  margin-left: 10px;
`
