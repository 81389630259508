import { USER_SIGN_IN_SUCCESS, USER_SIGN_IN_ERROR, USER_SIGN_OUT } from '../constants'

export const signIn = (code) => {
  return async (dispatch) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/slack/oauth?code=${code}`)
    const body = await response.json()
    if (body.ok) {
      localStorage.setItem('Authorization', body.token)
      dispatch({
        type: USER_SIGN_IN_SUCCESS,
        payload: body
      })
    } else {
      dispatch({
        type: USER_SIGN_IN_ERROR,
        payload: body
      })
    }
  }
}

export const getCurrentUser = (token) => {
  return async (dispatch) => {
    const response = await window.fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    })
    const body = await response.json()
    if (body.ok) {
      dispatch({
        type: USER_SIGN_IN_SUCCESS,
        payload: {
          ...body,
          token
        }
      })
    } else {
      localStorage.removeItem('Authorization')
    }
  }
}

export const signOut = _ => {
  localStorage.removeItem('Authorization')
  return {
    type: USER_SIGN_OUT
  }
}
