import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { CSSTransitionGroup } from 'react-transition-group'

import { StyleBase, ModalContent, CloseBtn } from './style'

const rootModal = document.getElementById('root-modal')

class Modal extends React.Component {
  constructor (props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount () {
    rootModal.appendChild(this.el)
    document.body.style.overflowY = 'hidden'
    window.addEventListener('keydown', this.closeModal)
  }

  componentWillUnmount () {
    rootModal.removeChild(this.el)
    document.body.style.overflowY = null
    window.removeEventListener('keydown', this.closeModal)
  }

  closeModal = e => {
    if (
      (e.type === 'click' &&
        e.target !== this.modal &&
        !this.modal.contains(e.target)) ||
      (e.type === 'keydown' && e.keyCode === 27)
    ) {
      this.props.onClose(e)
    }
  }

  render () {
    const { width, children, onClose } = this.props
    return ReactDOM.createPortal(
      <CSSTransitionGroup
        transitionName='modal'
        transitionAppear
        transitionAppearTimeout={150}
        transitionEnter={false}
        component={StyleBase}
        onClick={this.closeModal}
        transitionLeave={false} >
        <ModalContent
          ref={ref => this.modal = ref}
          width={width}>
          {children}
          <CloseBtn aria-label="Close" onClick={onClose}>
            <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Quizzes-Create-Quizz-Copy" transform="translate(-1322.000000, -196.000000)">
                  <g id="Add-New-Questions" transform="translate(356.000000, 168.000000)">
                    <g id="20x20/cross" transform="translate(958.000000, 20.000000)">
                      <g id="16x16/edit--dark">
                        <g id="20x20/edit--dark">
                          <mask id="mask-2" fill="white">
                            <use xlinkHref="#path-1" />
                          </mask>
                          <g id="Mask"/>
                        </g>
                      </g>
                      <path d="M9.6,9.6 L22.5137085,22.5137085" id="Line" stroke="#2D2D2D" strokeWidth="2.40000004" />
                      <path d="M9.6,9.6 L22.5137085,22.5137085" id="Line" stroke="#2D2D2D" strokeWidth="2.40000004" transform="translate(16.056854, 16.056854) scale(-1, 1) translate(-16.056854, -16.056854) " />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </CloseBtn>
        </ModalContent>
      </CSSTransitionGroup>,
      this.el
    )
  }
}

Modal.propTypes = {
  width: PropTypes.number,
  children: PropTypes.any,
  onClose: PropTypes.func.isRequired
}

export default Modal
